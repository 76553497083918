import axios from "axios";
import { getSelectedLocale } from "src/utils/storage-utils";
import { auth } from "./firebase";

export const BASE_URL = `https://${window.SERVER_DATA.REACT_APP_API_URL}`;

function isOwnDomain(config) {
  if (config.url.startsWith("/")) return true;
  try {
    const url = new URL(config.url);
    return url.hostname === window.SERVER_DATA.REACT_APP_API_URL;
  } catch (error) {
    return false;
  }
}

const langugageInterceptor = (config) => {
  config.headers["X-Language"] = getSelectedLocale();
  return config;
};

const companyInterceptor = (config) => {
  config.headers["X-Company-Name"] = window.SERVER_DATA.REACT_APP_TENANT_NAME;
  return config;
};

const defaultInterceptor = (config) => {
  if (!isOwnDomain(config) || config.method === "options") return config;
  config = langugageInterceptor(config);
  config = companyInterceptor(config);
  return config;
};

const authInterceptor = async (config) => {
  const token = await auth.currentUser?.getIdToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const requestTimeLogger = (config) => {
  config.metadata = { start: Date.now() };
  console.log(
    `outgoing: %c ${new Date(config.metadata.start).toISOString().split("T")[1]} | ${
      config.url
    }`,
    "color: #758df7;"
  );
  return config;
};

const responseTimeLogger = (response) => {
  const config = response.config;
  config.metadata.end = Date.now();
  config.metadata.duration = config.metadata.end - config.metadata.start;
  console.table({
    url: response.config.url,
    start: new Date(config.metadata.start).toISOString().split("T")[1],
    end: new Date(config.metadata.end).toISOString().split("T")[1],
    duration: `${config.metadata.duration}ms`,
  });
  return response;
};

export const apiClient = axios.create({ baseURL: BASE_URL, timeout: 30000 });
export const apiClientAuth = axios.create(apiClient.defaults);

[apiClient, apiClientAuth].forEach((client) => {
  client.interceptors.request.use(requestTimeLogger);
  client.interceptors.response.use(responseTimeLogger);
  client.interceptors.request.use(defaultInterceptor);
});

apiClientAuth.interceptors.request.use(authInterceptor);
